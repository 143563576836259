<template>
  <div>
    <!-- <loader2 v-if="load == true"></loader2> -->
    <section class="breadcrumb-outer text-center bg-orange">
      <div class="container">
        <h2>{{ $t("Header.PressService.Photo") }}</h2>
      </div>
    </section>
    <div class="container" style="text-align: center; margin-top: 30px">
      <div class="row">
        <div class="col-12">
          <div>
            <img
              class="image"
              v-for="(image, i) in images"
              :key="i"
              :src="image"
              @click="onClick(i)"
            />
          </div>
          <vue-gallery-slideshow
            style="height: 0px"
            v-for="(image, i) in images"
            :key="i"
            :images="images"
            :index="index"
            @close="index = null"
          >
          </vue-gallery-slideshow>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../apis";
import VueGallerySlideshow from "vue-gallery-slideshow";
export default {
  name: "Photo",
  components: {
    VueGallerySlideshow,
  },
  data() {
    return {
      index: 0,
      images: [],
      number: [1],
      load: true,
      // images : []
    };
  },
  methods: {
    onClick(i) {
      this.index = i;
    },
    async makeRequest() {
      try {
        const res = await api
          .get("/gallery/" + this.$route.params.id)
          .then((response) => {
            setTimeout(() => (this.load = false), 500);
            this.images = response.data.details.map(
              (el) => `https://backend.scocenter.uz/storage/${el.image}`
            );
          });
      } catch (error) {
        console.error(error);
      }
    },
  },
  mounted() {
    return this.makeRequest();
  },
};
</script>
<style scoped>
@media (max-width: 400px) {
  .breadcrumb-outer h2 {
    padding: 100px 0 15px !important;
  }
}
.bg-orange {
  margin: 0;
  background-color: #3498db !important;
  background-image: none;
}
section {
  padding: 40px 0 !important;
  position: relative;
}
.breadcrumb-outer h2 {
  padding: 70px 0 5px !important;
}
.vgs__container {
  width: 100%;
}
body {
  font-family: sans-serif;
}
.image {
  width: 350px;
  height: 300px;
  background-size: contain;
  cursor: pointer;
  margin: 10px;
  border-radius: 3px;
  border: 5px solid #3498db;
  /* filter: grayscale(100%); */
  border-radius: 26px;
  transition: all ease-in-out 0.5s;
}
img:hover {
  filter: grayscale(100%);
  border-radius: 26px;
  transition: all ease-in-out 0.5s;
}
</style>